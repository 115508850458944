import React from "react";
import "./Skills.css";
import SkillSection from "./SkillSection";
import ProjectsSection from "./ProjectsSection";
import { Fade } from "react-reveal";

export default function Skills(props) {
  const theme = props.theme;
  return (
    <div className="main" id="skills">
      <div className="skills-header-div ">
        <h2 className="skills-heading pretitle" style={{ color: theme.text }}>
          {"¿Que Hacemos?"}
        </h2>
        <Fade bottom duration={2000} distance="20px">
          <h1 className="skills-header" style={{ color: theme.text }}>
            Nos Adaptamos A Las Necesidades De Tu Proyecto
          </h1>
        </Fade>
      </div>
      <SkillSection theme={theme} />
      <div className="skills-header-div">
        <h2 className="skills-heading pretitle" style={{ color: theme.text }}>
          {"¿Proyectos?"}
        </h2>
        <Fade bottom duration={2000} distance="20px">
          <h1 className="skills-header" style={{ color: theme.text }}>
            Algunos de nuestros proyectos
          </h1>
        </Fade>
      </div>
      <ProjectsSection theme={theme} />
    </div>
  );
}
