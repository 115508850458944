import React, { useState } from 'react';
import BlogCard from './BlogCard';
import "./blog.scss";
import { Fade } from "react-reveal";
import blogData from './BlogData';

const BlogPage = (props) => {
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('');

  const theme = props.theme;

  // Función para abrir el modal con los detalles del blog
  const handleCardClick = (id) => {
    const blog = blogData.find(b => b.id === id);
    setSelectedBlog(blog);
    setIsModalOpen(true);
  };

  // Función para cerrar el modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedBlog(null);
  };

  // Manejar envío de formulario
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes añadir la lógica para enviar el PDF al correo
    alert(`PDF será enviado a ${email}`);
  };

  return (
    <div id="blog">
      <div className="skills-header-div">
        <h2 className="skills-heading pretitle" style={{ color: theme.text }}>
          {"Nuestro Blog"}
        </h2>
        <Fade bottom duration={2000} distance="20px">
          <h1 className="skills-header" style={{ color: theme.text }}>
            Posts
          </h1>
        </Fade>
      </div>
      <div className="container-services-main">
        {blogData.map((blog) => (
          <div
            key={blog.id}
            style={{ width: '300px', margin: '10px', cursor: 'pointer' }}
            onClick={() => handleCardClick(blog.id)}
          >
            <BlogCard
              title={blog.title}
              date={blog.date}
              description={blog.description}
              imageUrl={blog.imageUrl}
            />
          </div>
        ))}
      </div>

      {/* Modal para mostrar detalles del blog */}
      {isModalOpen && selectedBlog && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            
            {/* Imagen y contenido del blog */}
            <div className="modal-body">
              <div className="modal-image">
                <img src={selectedBlog.imageUrl} alt={selectedBlog.title} />
              </div>
              <div className="modal-text">
                <h2>{selectedBlog.title}</h2>
                <p><strong>Fecha:</strong> {selectedBlog.date}</p>
                <p>{selectedBlog.description}</p>
                <div className="modal-full-content">
                  <p>{selectedBlog.content}</p>
                </div>
              </div>
            </div>

            {/* Formulario para solicitar PDF */}
            <div className="modal-form">
              <h3>Te enviamos un pdf con la información completa</h3>
              <form onSubmit={handleFormSubmit}>
                <label htmlFor="email">Correo electrónico:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="tu-email@ejemplo.com"
                />
                <button type="submit">Enviar PDF</button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogPage;
