import React, { useState } from 'react';
import ReactDOM from 'react-dom';

const ImageViewer = ({ src, alt }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleViewer = () => {
    setIsOpen(!isOpen);
  };

  // Función para renderizar el visor en pantalla completa usando un portal
  const renderViewer = () => (
    <div
      onClick={toggleViewer}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        cursor: 'pointer',
      }}
    >
      <img 
        src={src} 
        alt={alt} 
        style={{ maxHeight: '90vh', maxWidth: '90vw', borderRadius: '8px' }} 
      />
    </div>
  );

  return (
    <div>
      {/* Imagen en miniatura */}
      <img 
        src={src} 
        alt={alt} 
        onClick={toggleViewer} 
        style={{ cursor: 'pointer', width: '200px' }} 
      />

      {/* Renderiza el visor de imagen en pantalla completa usando un portal */}
      {isOpen && ReactDOM.createPortal(renderViewer(), document.body)}
    </div>
  );
};

export default ImageViewer;
