import React from "react";
import "./Greeting.css";
import { greeting } from "../../portfolio";
import { Fade } from "react-reveal";
import { handleClickScroll } from "../../utils/handleClickScroll";
export default function Greeting(props) {
  return (
    <Fade bottom duration={2000} distance="40px">
    <div className="greet-main" id="greeting">
      <div className="greeting-main">
        <div className="greeting-text-div">
          {/* Contenedor responsive para el iframe */}
          <div className="video-responsive">
            <iframe
              src="https://www.youtube.com/embed/E40w9PQoFBc?autoplay=1&mute=1&controls=1&showinfo=1&modestbranding=1&playsinline=1"
              title="Welcome to Ysolve"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <h1 className="greeting-text">DESARROLLAMOS TÚ SOLUCIÓN TECNOLÓGICA</h1>
          <button
            className="button"
            style={{ background: "rgb(0,0,0,0.4)" }}
            onClick={() => {
              handleClickScroll("works");
            }}
          >
            {greeting.button}
          </button>
        </div>
      </div>
    </div>
  </Fade>
  
  
  );
}
