import React from "react";
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { skills } from "../../portfolio";
import { projects } from "../../portfolio";
import { Fade } from "react-reveal";
import ImageViewer from "../../components/ImageViewer/ImageViewe";
// import DesignImg from "./DesignImg";

function ProjectsSection(props) {
  const theme = props.theme;
  return (
    <div id='projects' className="skills-main-div" style={{flexDirection: 'column'}}>
      {projects.map((project, index) => {
          return (
            <Fade left duration={2000} distance="200px">
            <div className="project-div">
                <div>
                    <h1>{project.title}</h1>
                    <h2>{project.subtitle}</h2>
                    <div style={{paddingLeft: '2rem'}}>
                      <SoftwareSkill logos={project.technologies} />
                    </div>
                </div>
                <div className="imgdiv">
                    <ImageViewer alt={project.title} src={project.img}></ImageViewer>
                </div>
            </div>
            </Fade>
          );
        
      })}
    </div>
  );
}

export default ProjectsSection;
