import React from 'react';
import "./blog.scss";

const BlogCard = ({ title, date, description, imageUrl }) => {
  return (
    <div className="blog-card" style={styles.card}>
      <img src={imageUrl} alt={title} style={styles.image} />
      <div style={styles.content}>
        <h2 style={styles.title}>{title}</h2>
        <p style={styles.date}>{date}</p>
        <p style={styles.description}>{description}</p>
      </div>
    </div>
  );
};

const styles = {
  card: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
    cursor: 'pointer',
    width: '100%',
    maxWidth: '300px',
  },
  image: {
    width: '100%',
    height: '150px',
    objectFit: 'cover',
  },
  content: {
    padding: '16px',
    color: '#000'
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '8px',
    color:'#111'
  },
  date: {
    fontSize: '14px',
    color: '#888',
    marginBottom: '12px',
  },
  description: {
    fontSize: '16px',
    color: '#333',
  },
};

export default BlogCard;
