const blogData = [
    {
      id: 1,
      title: '¿Qué es una actualización OTA?',
      date: 'October 9 2024',
      description: 'Explorando las actualizaciones Over-The-Air (OTA).',
      imageUrl: require("../../assests/images/ota.webp"),
      
      content: `
        Las actualizaciones OTA (Over-The-Air) son una forma de actualizar el software de un dispositivo a través de la red, sin necesidad de cables o intervención manual. Este tipo de actualizaciones son comunes en teléfonos móviles, dispositivos IoT y sistemas embebidos. En este post, analizamos cómo funcionan las OTA, sus beneficios y los desafíos de seguridad que conllevan. Además, cubrimos el impacto de estas actualizaciones en la experiencia del usuario y la infraestructura necesaria para implementarlas.
      `,
    },
    {
      id: 2,
      title: 'Arquitectura por servicios en software web',
      date: 'September 16 2024',
      description: 'Ventajas de una arquitectura basada en servicios para aplicaciones web.',
      imageUrl: require("../../assests/images/serviciosArquitecture.png"),
      content: `
        La arquitectura por servicios, también conocida como microservicios, es un enfoque en el que una aplicación se divide en componentes independientes que interactúan a través de APIs. En este post, explicamos cómo esta arquitectura facilita el escalado de aplicaciones web, mejora el mantenimiento y permite la integración continua. También abordamos las mejores prácticas para diseñar sistemas escalables y cómo gestionar la comunicación entre servicios a través de tecnologías como REST y gRPC.
      `,
    },
    {
      id: 3,
      title: 'Autenticación con OAuth y IdClient',
      date: 'September 23 2024',
      description: 'Una introducción a la autenticación segura con OAuth 2.0 y el uso de IdClient.',
      imageUrl: require("../../assests/images/oauth.png"),
      content: `
        OAuth 2.0 es un estándar de autorización que permite a los usuarios otorgar acceso limitado a sus recursos sin compartir sus credenciales. IdClient es una forma de manejar la autenticación y autorización en aplicaciones web. En este post, exploramos cómo funciona OAuth, las ventajas de usar IdClient para manejar tokens de acceso, y cómo implementar este sistema de autenticación en una aplicación moderna. También discutimos las medidas de seguridad para proteger los datos y evitar vulnerabilidades.
      `,
    },
    {
      id: 4,
      title: 'React para aplicaciones multiplataforma',
      date: 'September 30 2024',
      description: 'Cómo React puede facilitar el desarrollo de aplicaciones multiplataforma.',
      imageUrl: require("../../assests/images/react2.png"),
      content: `
        React es una biblioteca JavaScript popular que permite desarrollar interfaces de usuario rápidas y dinámicas. Con herramientas como React Native y frameworks como Electron, React se ha convertido en una opción viable para el desarrollo multiplataforma. En este post, analizamos cómo React permite a los desarrolladores crear aplicaciones para web, móvil y escritorio utilizando un solo código base. También discutimos las ventajas de la reutilización de componentes y cómo optimizar el rendimiento en cada plataforma.
      `,
    }
  ];
  
  export default blogData;
  