import React from "react";
import "./Contact.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import { contactInfo } from "../../portfolio";
import { skills } from "../../portfolio";
import { Fade } from "react-reveal";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
// import DesignImg from "./DesignImg";

export default function Contact({ theme }) {
  return (
    <div className="main contact-margin-top" id="contact">
      {skills.data.map((skill, index) => {
        return (
          <div className="skills-main-div">
            <div
              className="skills-text-div article-who"
              style={{ margin: "0 auto" }}
            >
              <Fade left duration={1000}>
                <h2 className="skills-heading" style={{ color: theme.text }}>
                  {"Contacto"}
                </h2>
                <h1 className="skills-heading" style={{ color: theme.text }}>
                  {"¿Tienes un proyecto entre manos?"}
                </h1>
              </Fade>
              <Fade left duration={1000}>
                <div>
                  <p
                    className="subTitle skills-text"
                    style={{ color: theme.secondaryText, width: "95%" }}
                  >
                    {
                      "Puedes mandarnos un email utilizando este formulario o puedes llamarnos haciendo click en el siguiente botón:"
                    }
                  </p>
                </div>
              </Fade>
              <a href="tel:627669080" class="about-us-button">
                Llámanos
              </a>
            </div>
            <Fade right duration={1000}>
              <div className="skills-image-div">
                <form
                  action="mailto:danielburruchaga@ysolve.es"
                  enctype="text/plain"
                >
                  <div>
                    <label for="email">Correo electrónico:</label>
                    <input
                      name="email"
                      type="text"
                      placeholder="tu-email@ejemplo.com"
                    />
                    <label for="subject">Asunto:</label>
                    <input
                      name="subject"
                      type="text"
                      placeholder="Escribe un asunto..."
                    />
                    <label for="body">Mensaje:</label>
                    <textarea
                      name="body"
                      id="body"
                      cols="30"
                      rows="5"
                      placeholder="Escribe un cuerpo..."
                    ></textarea>
                    <input class="submit" type="submit" value="Enviar" />
                  </div>
                </form>
              </div>
            </Fade>
          </div>
        );
      })}
    </div>
  );
}
