import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";
import { greeting } from "../../portfolio.js";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
/* eslint-disable jsx-a11y/accessible-emoji */
import { handleClickScroll } from "../../utils/handleClickScroll";

export default function Footer(props) {
  return (
    <footer class="footer-distributed">
      <div class="footer-left">
        <img
          className="logo-img-footer"
          alt="logo"
          src={require("../../assests/images/logo.png")}
        />

        <p class="footer-links">
          <a onClick={() => handleClickScroll("home")}>Home</a>
          <a onClick={() => handleClickScroll("skills")}>Que hacemos</a>
          <a onClick={() => handleClickScroll("who-are-we")}>Nosotros</a>
          <a onClick={() => handleClickScroll("works")}>Proyectos</a>
          <a onClick={() => handleClickScroll("contact")}>Contacto</a>
          <a onClick={() => handleClickScroll("team")}>Equipo</a>
        </p>

        <p class="footer-company-name">Ysolve © 2021</p>
      </div>

      <div class="footer-center">
        <div>
          <i class="fa fa-map-marker"></i>
          <p>
            <span> Avenida Hermanos Machado 13, 204</span> Valencia, España
          </p>
        </div>

        <div>
          <a href="https://wa.me/604934292" target={"_blank"}>
            <i class="fa fa-phone"></i>
            <p>+34 604 93 42 92</p>
          </a>
        </div>

        <div>
          <i class="fa fa-envelope"></i>
          <p>
            <a href="mailto:info@ysolve.es">info@ysolve.es</a>
          </p>
        </div>
      </div>

      <div class="footer-right">
        <p class="footer-company-about">
          <span>Sobre Ysolve</span>
          Somos expertos en adaptarnos a las necesidades de tu proyecto,
          ofreciendo desarrollo de dispositivos IoT y firmware, plataformas web
          de gestión Full Stack, aplicaciones móviles y de escritorio
          multiplataforma. Ofrecemos soluciones personalizadas para diversos
          sectores, desde la sensorización agrónoma hasta el control de
          maquinaria industrial, y nos especializamos en protocolos de
          comunicación industrial y tecnologías inalámbricas.
        </p>

        <div class="footer-icons">
          <a
            href=" https://www.linkedin.com/company/79045707/admin/feed/posts/"
            target={"_blank"}
          >
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip-top`}>
                  <strong>{"linkedin"}</strong>
                </Tooltip>
              }
            >
              <li
                style={{
                  listStyle: "none",
                  marginLeft: "0.75rem",
                  fontSize: "20px",
                }}
                name={"linkedin"}
              >
                <span
                  className="iconify"
                  data-icon={"simple-icons:linkedin"}
                  data-inline="false"
                ></span>
              </li>
            </OverlayTrigger>
          </a>
        </div>
      </div>
    </footer>
  );
}
