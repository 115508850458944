/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "",
};

//Home Page
const greeting = {
  title: "DESARROLLO DE SOFTWARE",
  title2: "e ingeniería electrónica ",
  button: "Ver Proyectos  ",
  logo_name: "Ysolve",
  nickname: "Ysolve",
  full_name: "sensor ambiental.",
  subTitle:
    " Green box es un sensor ambiental completamente modular, integrado con diversas comunicaciones como Lora, 4G/GSM/LTE, Wifi. Centralización de datos en nuestra propia plataforma. ",
  resumeLink: "",
  mail: "danielburru@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/danielei9",
  linkedin: "https://www.linkedin.com/in/daniel-burruchaga//",
  gmail: "danielburru@gmail.com",
  gitlab: "",
  facebook: "",
  twitter: "",
  instagram: "https://www.instagram.com/buchspro/",
};
const aptitudes = [
  {
    img: require("./assests/images/website.png"),
    title: "Plataformas web de gestión",
    subtitle: "Diseño, desarrollo y deploy... Full Stack",
    text:
      "Desarrollo de aplicaciones web de gestión, multiplataforma, diseño, front end y back end. Nos adaptamos a tus necesidades.",
  },
  {
    img: require("./assests/images/undraw_online_transactions_02ka.png"),
    title: "Desarrollo de dispositivos IoT",
    subtitle: "Desarrollo electrónico y firmware",
    text:
      "Prototipado, desarrollo y diseño de placas electrónicas relacionadas con IoT, smart-city... Nos adaptamos a cualquier sector personalizando nuestros desarrollos como: pasarela de maquinaria industrial a internet, sensorización agrónoma, control de maquinaria industrial... Diseño de planos y archivos de fabricación.",
  },
  // {
  //   img: require("./assests/images/ia.png"),
  //   title: "Inteligencia Artificial",
  //   subtitle: "Modelado, entrenamiento y desarrollo",
  //   text:
  //     "Inteligencia artificial para obtener prediciones, chatbots, analisis y comprensión de lenguaje para Intelligent Assistant, algoritmos de automatización... Captura datos para analizarlos, y obtener resultados. Optimización de tiempo.",
  // },
  {
    img: require("./assests/images/app.png"),
    title: "Desarrollo de aplicaciones",
    subtitle: "Moviles y Ordenadores",
    text:
      "Desarrollamos para múltiples plataformas: Android, iOS, Windows, Linux y ARM. Controla tus proyectos desde cualquier lugar con nuestras soluciones versátiles.",
  },
  {
    img: require("./assests/images/firmware.png"),
    title: "Desarrollo de Diseño Industrial",
    subtitle: "Diseño Industrial",
    text:
      "Nos destacamos en diseño industrial de planos y modelado 3D, ofreciendo soluciones creativas, modernas y funcionales. Nuestro equipo cuenta con especialistas en la creación de diseños precisos y detallados, utilizando tecnologías avanzadas de modelado 3D para visualizar productos y prototipos. ",
  },
  // {
  //   img: require("./assests/images/vision.png"),
  //   title: "Visión artificial",
  //   subtitle: "Machine Learning",
  //   text:
  //     "En nuestro equipo contamos con especialistas en desarrollo de Machine Learning para solucionar problemas mediante visión artificial, separación y clasificación de modelos...",
  // },
];

const projects = [
  {
    title: "Plataforma Web O-CITY",
    subtitle: "Desarrollo web. Arquitectura por servicios. Plataforma base de datos y geoposicionamiento de monumentos históricos.",
    img: require("./assests/images/ocity.png"),
    backgroundColor: "#bbbbb",
    technologies: [
      {
        skillName: "Typescript",
        fontAwesomeClassname: "simple-icons:typescript",
        style: {
          color: "#1183C5FF",
        },
      },
      {
        skillName: "React",
        fontAwesomeClassname: "simple-icons:react",
        style: {
          color: "#1183C5FF",
        },
      },
      {
        skillName: "Postgresql",
        fontAwesomeClassname: "simple-icons:postgresql",
        style: {
          color: "#1183C5FF",
        },
      },
      {
        skillName: "nginx",
        fontAwesomeClassname: "simple-icons:nginx",
        style: {
          color: "#1183C5FF",
        },
      },{
        skillName: "Docker",
        fontAwesomeClassname: "simple-icons:docker",
        style: {
          color: "#1183C5FF",
        },
      },
      {
        skillName: "GitHub Actions",
        fontAwesomeClassname: "simple-icons:githubactions",
        style: {
          color: "#5b77ef",
        },
      },
      {
        skillName: "AWS",
        fontAwesomeClassname: "simple-icons:amazonwebservices",
        style: {
          color: "#1488C6",
        },
      },
    ],
  },
  {
    title: "Plataforma Web ForoPromis",
    subtitle: "Desarrollo web. Arquitectura por servicios. Administración de asociaciones de deporte y geoposicionamiento.",
    img: require("./assests/images/foropromis.png"),
    backgroundColor: "#bbbbb",
    technologies: [
      {
        skillName: "Typescript",
        fontAwesomeClassname: "simple-icons:typescript",
        style: {
          color: "#1183C5FF",
        },
      },
      {
        skillName: "Postgresql",
        fontAwesomeClassname: "simple-icons:postgresql",
        style: {
          color: "#1183C5FF",
        },
      },
      {
        skillName: "nginx",
        fontAwesomeClassname: "simple-icons:nginx",
        style: {
          color: "#1183C5FF",
        },
      },{
        skillName: "Docker",
        fontAwesomeClassname: "simple-icons:docker",
        style: {
          color: "#1183C5FF",
        },
      },
    ],
  },
  {
    title: "App Multiplataforma DOKI",
    subtitle: "App multiplataforma para la conexion de usuarios en networking.",
    img: require("./assests/images/Doki.PNG"),
    backgroundColor: "#bbbbb",
    technologies: [
      {
        skillName: "Typescript",
        fontAwesomeClassname: "simple-icons:typescript",
        style: {
          color: "#1183C5FF",
        },
      },
      {
        skillName: "Postgresql",
        fontAwesomeClassname: "simple-icons:postgresql",
        style: {
          color: "#1183C5FF",
        },
      },
      {
        skillName: "nginx",
        fontAwesomeClassname: "simple-icons:nginx",
        style: {
          color: "#1183C5FF",
        },
      },
      {
        skillName: "Docker",
        fontAwesomeClassname: "simple-icons:docker",
        style: {
          color: "#1183C5FF",
        },
      },
      {
        skillName: "React",
        fontAwesomeClassname: "simple-icons:react",
        style: {
          color: "#1183C5FF",
        },
      },
    ],
  },
  {
    title: "Plataforma IOT",
    subtitle: "Plataforma App Web para el control de los dispositivos IOT Lora",
    img: require("./assests/images/Ges.png"),
    backgroundColor: "#bbbbb",
    technologies: [
      {
        skillName: "Typescript",
        fontAwesomeClassname: "simple-icons:typescript",
        style: {
          color: "#1183C5FF",
        },
      },
      {
        skillName: "MQTT",
        fontAwesomeClassname: "simple-icons:mqtt",
        style: {
          color: "#1183C5FF",
        },
      },
      {
        skillName: "Sequelize",
        fontAwesomeClassname: "simple-icons:sequelize",
        style: {
          color: "#188F01FF",
        },
      },
      {
        skillName: "MYSQL",
        fontAwesomeClassname: "simple-icons:mysql",
        style: {
          color: "#6863a6",
        },
      },
      {
        skillName: "Postgresql",
        fontAwesomeClassname: "simple-icons:postgresql",
        style: {
          color: "#1183C5FF",
        },
      },
      {
        skillName: "nginx",
        fontAwesomeClassname: "simple-icons:nginx",
        style: {
          color: "#1183C5FF",
        },
      },
      {
        skillName: "Docker",
        fontAwesomeClassname: "simple-icons:docker",
        style: {
          color: "#1183C5FF",
        },
      },
      {
        skillName: "React",
        fontAwesomeClassname: "simple-icons:react",
        style: {
          color: "#1183C5FF",
        },
      },
    ],
  },
  
  {
    title: "Máquinas de Autocobro",
    subtitle: "Diseño industrial, desarrollo firmware, desarrollo electrónico. Software TPV multiplataforma.",
    img: require("./assests/images/uce.png"),
    backgroundColor: "#bbbbb",
    technologies: [
      {
        skillName: "Typescript",
        fontAwesomeClassname: "simple-icons:typescript",
        style: {
          color: "#1183C5FF",
        },
      },
      {
        skillName: "Python",
        fontAwesomeClassname: "simple-icons:python",
        style: {
          color: "#1488C6",
        },
      },
      {
        skillName: "Mqtt",
        fontAwesomeClassname: "simple-icons:mqtt",
        style: {
          color: "#1488C6",
        },
      },
      {
        skillName: "Raspberrypi 3",
        fontAwesomeClassname: "simple-icons:raspberrypi",
        style: {
          color: "#1488C6",
        },
      }, {
        skillName: "React",
        fontAwesomeClassname: "simple-icons:react",
        style: {
          color: "#1488C6",
        },
      },
      {
        skillName: "Mysql",
        fontAwesomeClassname: "simple-icons:mysql",
        style: {
          color: "#6863a6",
        },
      },
      {
        skillName: "Electron",
        fontAwesomeClassname: "simple-icons:electron",
        style: {
          color: "#1488C6",
        },
      },
      {
        skillName: "Windows",
        fontAwesomeClassname: "simple-icons:windows",
        style: {
          color: "#1488C6",
        },
      },
    ],
  },
  {
    title: "GreenBox - Sensor Ambiental",
    subtitle:
      "Proyecto publico para detectar el deterioro de los monumentos con los gases que lo rodean + plataforma IOT 4G y LORA.",
    img: require("./assests/images/sensor.png"),
    backgroundColor: "#bbbbb",
    technologies: [
      {
        skillName: "Typescript",
        fontAwesomeClassname: "simple-icons:typescript",
        style: {
          color: "#1488C6",
        },
      },
      {
        skillName: "React",
        fontAwesomeClassname: "simple-icons:react",
        style: {
          color: "#1488C6",
        },
      },
      {
        skillName: "Mqtt",
        fontAwesomeClassname: "simple-icons:mqtt",
        style: {
          color: "#1488C6",
        },
      },
      {
        skillName: "Mysql",
        fontAwesomeClassname: "simple-icons:mysql",
        style: {
          color: "#6863a6",
        },
      },
      {
        skillName: "Electron",
        fontAwesomeClassname: "simple-icons:electron",
        style: {
          color: "#1488C6",
        },
      },

      {
        skillName: "TTGO v21",
        fontAwesomeClassname: "simple-icons:arduino",
        style: {
          color: "#1488C6",
        },
      },
      {
        skillName: "C++",
        fontAwesomeClassname: "simple-icons:cplusplus",
        style: {
          color: "#1488C6",
        },
      },
      
    ],
  },
];
const skills = {
  data: [
    {
      pretitle: "¿Quienes Somos?",
      title: "Especializados en el desarrollo de Software y Hardware",
      fileName: "CloudInfraImg",
      skills: [
        "💕  En Ysolve desarrollamos ideas. No nos limitamos a programar, si no que nos implicamos al 100% con tu proyecto. Asesoramos tanto en tecnología como en UX/UI y aportamos nuestra propia experiencia en productos físicos y digitales. Dedicamos tiempo y valoramos las ideas y proyectos de nuestros clientes, incluso antes de que exista compromiso alguno.",
        "🧠 Ayudamos a mejorar el propio proyecto",
        "👨🏻‍💻 Nos caracterizamos por perseguir la innovación e intentamos incluso mejorar las ideas del proyecto. Adaptamos la tecnología al proyecto, somos flexibles y buscamos la mejor solución para obtener un resultado de calidad y con un estilo propio de nuestro equipo.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "vscode-icons:file-type-html",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Javascript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            color: "#F8E91A",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "React JS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },
      ],
    },
  ],
};
const misionVisionValores = [
  {
    img: require("./assests/images/undraw_online_transactions_02ka.png"),
    title: "Misión",
    subtitle: "Soluciones tecnológicas innovadoras",
    text:
      "En Ysolve, nuestra misión es transformar desafíos en soluciones tecnológicas innovadoras. Trabajamos incansablemente para brindar a nuestros clientes la capacidad de superar obstáculos y alcanzar sus objetivos a través del desarrollo de software, hardware y diseño industrial. Nos comprometemos a adaptarnos a las necesidades únicas de cada proyecto, combinando creatividad y experiencia para generar impacto positivo en diversos sectores.",
  },
  {
    img: require("./assests/images/website.png"),
    title: "Visión",
    subtitle: "Soluciones tecnológicas personalizadas",
    text:
      "Nuestra visión es ser líderes reconocidos en el ámbito de soluciones tecnológicas personalizadas. Aspiramos a ser la primera elección para aquellos que buscan resolver problemas complejos y aprovechar al máximo el potencial de la tecnología. Visualizamos un futuro en el que nuestras soluciones impulsen la innovación en múltiples industrias, mejorando la vida de las personas y contribuyendo al progreso global.",
  },
  {
    img: require("./assests/images/app.png"),
    title: "Valores",
    subtitle: "Innovación, Compromiso, Calidad y Responsabilidad",
    text: `Estamos completamente comprometidos con los proyectos y metas de nuestros clientes, priorizando su éxito. Adaptabilidad es nuestra fortaleza: nos ajustamos a los cambios en los proyectos y sectores, permitiéndonos abordar cualquier desafío y brindar soluciones adecuadas. La excelencia es nuestra norma, ya que como expertos en tecnología, perseguimos la calidad en cada faceta de nuestro trabajo. Operamos con integridad y transparencia, manteniendo relaciones comprometidas y claras con nuestros clientes y socios.`,
  },
];
const degrees = {
  degrees: [
    {
      title: "Técnico en Instalaciones de Telecomunicaciones",
      subtitle: "Grado medio Juan Comenius",
      logo_path: "Comenius.PNG",
      alt_name: "SSGC",
      duration: "2015 - 2017",
      descriptions: [
        "⚡ Montaje y mantenimiento de infraestructuras de telecomunicación ",
        "⚡ Instalaciones de circuito cerrado de televisión y seguridad electrónica,",
        "⚡ Centralitas telefónicas e infraestructuras de redes de voz y datos. ",
        "⚡ Instalaciones de radiocomunicaciones. ",
        "⚡ Sistemas domóticos y equipos informáticos. ",
      ],
      website_link:
        "https://www.comenius.es/?class=instalaciones-de-telecomunicaciones-2",
    },
    {
      title: "Técnico Superior mantenimiento electrónico",
      subtitle: "Grado Superior Juan Comenius",
      logo_path: "Comenius.PNG",
      alt_name: "SSGC",
      duration: "2017 - 2019",
      descriptions: [
        "⚡ Mantenimiento de equipos de radiocomunicaciones.",
        "⚡Mantenimiento de equipos de voz y datos.",
        "⚡Mantenimiento de equipos de audio.",
        "⚡Mantenimiento de equipos de vídeo.",
      ],
      website_link:
        "https://www.comenius.es/?class=mantenimiento-electronico-2",
    },
    {
      title: "Tecnologias Interactivas UPV",
      subtitle:
        "Grado en Ing Tecnologias Interactivas Universidad Politécnica de Valencia",
      logo_path: "UPV.png",
      alt_name: "UPV",
      duration: "2019 - Presente",
      descriptions: [
        "⚡ Diseño y programación Web",
        "⚡ Diseño Electrónico y programación firmware",
        "⚡ IOT / APPS (ios/android/web)",
        "⚡ Inteligencia Artificial y robótica",
        "⚡ Visión Artificial y Realiada Virtual",
        "⚡ Seguridad en redes",
      ],
      website_link: "https://www.upv.es/titulaciones/GTI/indexc.html",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Node,Express, React & MySql full stack web development",
      subtitle: "Udemy",
      logo_path: "react.png",
      certificate_link:
        "https://drive.google.com/file/d/1mxzOFWfg1hQIr_Is7gmJWeyW3BeDgvUe/view?usp=sharing",
      alt_name: "Udemy",
      color_code: "#2AAFED",
      //color_code: "#47A048",
    },
    {
      title: "Curso Completo de bases de datos MongoDB y NoSql",
      subtitle: "Udemy",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/1oLs-bvdgznRlg1qh2ocoDfEmFAsqkgyT/view?usp=sharing",
      alt_name: "Udemy",
      color_code: "#47A048",
      //color_code: "#E2405F",
    },
    {
      title: "Certificado Javascript",
      subtitle: "CodigoFacilito",
      logo_path: "javascript.png",
      certificate_link:
        "https://drive.google.com/file/d/1hJfxEuuj0E1MD62GPtjqSJsG7S4SbFdo/view?usp=sharing",
      alt_name: "CodigoFacilito",
      color_code: "#F6B808",
      //color_code: "#47A048",
    },
    {
      title: "Junior Pentester",
      subtitle: "TryHackMe",
      logo_path: "iit.png",
      certificate_link: "",
      alt_name: "TryHackMe",
      color_code: "#2AAFED",
    },
    {
      title: "Postman Student Expert",
      subtitle: "Postman",
      logo_path: "postman.png",
      certificate_link:
        "https://badgr.com/public/assertions/h2qevHs4SlyoHErJQ0mn2g",
      alt_name: "Postman",
      // color_code: "#f36c3d",
      color_code: "#fffbf3",
    },
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

// Experience Page
const experience = {
  title: "Experiencias",
  subtitle: "Trabajos, erasmus, competiciones...",
  description: "",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Puestos de Trabajo",
      experiences: [
        {
          title: "Prácticas Ogenica i+d",
          company: "Ogenica",
          company_url: "http://Ogenica.com/",
          logo_path: "ogenica.jpg",
          duration: "Marzo 2016 - Junio 2016",
          location: "Burjassot Valencia",
          description: `
          Prácticas en Ogenica Ingenieria. Donde realicé un proyecto de estación de trabajo de electrónica: fuente de alimentación,voltimetro, amperímetro, ohmetro... y 
          todo conectado a una App android donde visualizar los datos.
          `,
          // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#FF29AD",
        },
        {
          title: "Técnico Superior",
          company: "Tecnosel",
          company_url: "http://tecnosel.com/",
          logo_path: "tecnosel.png",
          duration: "Marzo 2016 - Sept 2019",
          location: "Chirivella - Valencia",
          description: `Encargado de máquina SMT, CNC, horno de placas electrónicas, reparación, programación... LLegué a estar a cargo de la producción en masa de placas electrónicas,
          mediante una máquina SMT pick and place, y de todo el proceso de producción.
          `,
          // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#A80054",
        },
        {
          title: "Scrum Master / Desarrollador",
          company: "Gesinen S.L",
          company_url: "https://gesinen.es/",
          logo_path: "gesinen.jpg",
          duration: "Junio 2021 - Actual",
          location: "Remoto",
          description: `Scrum master, organizador de proyectos y desarrollador. Soluciones tecnológicas. Sobre todo trabajamos con protocolos de comunicación Lora / LTE / 4G.
          Firmware y web.
          `,
          color: "#FF29AD",
        },
      ],
    },
    {
      title: "Erasmus",
      experiences: [
        {
          title: "Erasmus + Precision",
          company: "Precision",
          company_url: "",
          logo_path: "Comenius.PNG",
          duration: "Junio 2015 - Sept 2015",
          location: "Lisboa - Portugal",
          description: `Se me brindó la ocasión de ir a una gran empresa en Lisboa, Portugal. Precision, donde me encargaba de la electronica de coches.
          `,
          color: "#A80054",
        },
      ],
    },
    {
      title: "Emprendimiento",
      experiences: [
        {
          title: "Proyecto Propio StartUp Ysolve",
          company: "Ysolve",
          company_url: "http://ysolve.es/",
          logo_path: "ysolve.png",
          duration: "abril 2021 - Actual",
          location: "Gandía - Valencia",
          description: `Desarrollo de soluciones tecnológicas, hemos realizado máquinas de auto pago, plataforma web, multiplataforma...
          `,
          // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#FF29AD",
        },
        {
          title: "Proyecto Propio StartUp M2U",
          company: "Music2U",
          company_url: "http://music2u.es/",
          logo_path: "music2u.png",
          duration: "junio 2021 - Actual",
          location: "Gandía - Valencia",
          description: `Desarrollo de empresa. Se basa en una App de inversion en musica y artistas. Con musica streming, blogs...
          `,
          // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#A80054",
        },
        {
          title: "Concurso Aula emprende",
          company: "UPV",
          company_url: "http://upv.es/",
          logo_path: "UPV.png",
          duration: "octubre 2022",
          location: "Gandía - Valencia",
          description: `Participación en concurso aula emprende Valencia UPV.
          `,
          // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#FF29AD",
        },
        {
          title: "Concurso 2k22 Instituto ideas",
          company: "UPV",
          company_url: "http://upv.es/",
          logo_path: "UPV.png",
          duration: "octubre 2022",
          location: "Gandía - Valencia",
          description: `Participación en concurso 2k22 Valencia UPV.
          `,
          // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#A80054",
        },
      ],
    },
    {
      title: "Competiciones",
      experiences: [
        {
          title: "1º Premio FP skills Valencia",
          company: "FP Skills",
          company_url: "",
          logo_path: "valencia.png",
          duration: "octubre 2017 ",
          location: "Valencia",
          description: "1º Premio en FP Skills Valencia",
          color: "#FF29AD",
        },
        {
          title: "3º Premio FP skills Comunidad Valenciana",
          company: "FP Skills",
          company_url: "",
          logo_path: "valencia.png",
          duration: "octubre 2017 ",
          location: "Alicante",
          description: "3º Premio en FP Skills Comunidad Valenciana",
          color: "#A80054",
        },
        {
          title: "Competición SigueLineas Comunidad Valenciana",
          company: "FP Skills",
          company_url: "",
          logo_path: "valencia.png",
          duration: "noviembre 2017 / 2018 / 2019",
          location: "Alcoi",
          description: "Participación en la competición siguelineas ",
          color: "#FF29AD",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Proyectos",
  description:
    "Mis proyectos tienen mucha variedad de tecnologias y herramientas. He realizado desde simples paginas web, app multiplataformas, plataformas web, sensores inteligentes, smart city Componentes, prototipado electrónico...Dejo algunos de mis trabajos aqui...",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "Daniel.jpg",
    description:
      "Puedes contactar conmigo desde cualquier método nombrado posteriormente. Trataré de contestarte lo mas rápido posible ;) ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "No suelo postear mucho pero a veces lo hago, podeis revisar mi trabajo día a día en instagram (@buchspro) o linkedin (GreenBox )",
    link: "https://instagram.com/buchspro",
    link2: "https://www.linkedin.com/in/daniel-burruchaga/",
    avatar_image_path: "blogs_image.svg",
  },
};
export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  aptitudes,
  projects,
  misionVisionValores,
};
